import { RteRte, RteRteLinks } from '@contentfulTypes'
import { useSubscribeEmailToNewsletterMutation } from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { DEFAULT_LOCALE, EMAIL_PATTERN } from '~/config/constants'
import { ButtonMain } from '~/elements/Button/ButtonMain'
import { Input } from '~/elements/Input/Input'
import { createMagentoClient } from '~/graphql/magentoClient'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { RTEParser } from '~/lib/RTEParser/RTEParser'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { parseMagentoGraphqlError } from '~/lib/parseMagentoGraphlError'

type FormData = {
  email: string
}
type Props = {
  disclaimer?: string | undefined | null
  disclaimerRte?: any
  hasDesktopPadding?: boolean
  noMessage?: boolean
  isFooter?: boolean
  isBandPopup?: boolean
}
export const NewsletterForm = ({
  disclaimer,
  disclaimerRte,
  isFooter,
  hasDesktopPadding = true,
  noMessage = false,
  isBandPopup,
}: Props) => {
  const [subscribeSuccessful, setSubscribeSuccessful] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const router = useRouter()
  const { dataLayer } = useGTM()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const mutationClient = createMagentoClient({ queryClient, usePost: true, locale: finalLocale })
  const newsletterMutation = useSubscribeEmailToNewsletterMutation(mutationClient, {
    onSuccess: async (data) => {
      toast.success(
        t('You are almost there! Please confirm your subscription in the email we just sent to'),
      )
      setSubscribeSuccessful(true)
    },
    onError: async (error) => {
      const parsedError = parseMagentoGraphqlError(error)
      if (parsedError?.extensions?.category.includes('already-exists')) {
        toast.error(t('This email address is already subscribed'))
      } else if (error.message.includes('Guests can not subscribe')) {
        toast.error(
          t('Guests can not subscribe to the newsletter. Please create an account to subscribe.'),
        )
      } else if (error.message.includes('account sign-in was incorrect')) {
        toast.error(
          t(
            'Your account is pending confirmation or disabled temporarily. Please try again later.',
          ),
        )
      } else {
        toast.error(t('Something went wrong. Please try again later.'))
      }
      setSubscribeSuccessful(false)
    },
  })
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = handleSubmit(async ({ email }) => {
    newsletterMutation.mutate({ email })
    dataLayer(DataLayerEnum.EVENT, 'sign_up', {
      dataLayer: {
        method: isFooter ? 'footer_form' : isBandPopup ? 'banderole_popup' : 'hp_box',
        user_data: {
          email_address: email,
        },
      },
    })
    setCurrentEmail(email)
    reset()
  })

  return (
    <>
      <form onSubmit={onSubmit} className={clsx('mb-6 mt-6 px-4', hasDesktopPadding && 'lg:pr-20')}>
        <Input<FormData>
          id="newsletter_footer_email"
          name="email"
          type="text"
          placeholder={`${t('E-Mail Address')}`}
          autoComplete="email"
          rules={{
            required: {
              value: true,
              message: t('{{val}} is required', { val: t('E-Mail') }),
            },
            pattern: {
              value: EMAIL_PATTERN,
              message: t('Please enter a valid email'),
            },
          }}
          register={register}
          errors={errors}
        />
        {subscribeSuccessful && !noMessage ? (
          <p>
            {`${t(
              'You are almost there! Please confirm your subscription in the email we just sent to',
            )} ${currentEmail}.`}
          </p>
        ) : null}
        <ButtonMain className="mt-1" fullWidth type="submit">
          {t('Subscribe')}
        </ButtonMain>
      </form>
      {disclaimer ? (
        <p className={clsx('mb-6 px-4 text-grey-dark lg:mb-10 lg:pr-20 [&_p]:text-12-20-sg')}>
          {disclaimer}
        </p>
      ) : null}
      {disclaimerRte ? (
        <RTEParser
          className={clsx('mb-6 px-4 text-grey-dark lg:mb-10 lg:pr-20 [&_p]:text-12-20-sg')}
          rteJSONContent={disclaimerRte?.json}
          links={disclaimerRte?.links as RteRteLinks}
        />
      ) : null}
    </>
  )
}
