import * as React from 'react'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { ErrorMessage } from '@hookform/error-message'
import styles from './Input.module.css'
import { RegisterOptions, UseFormRegister, Path, FieldErrors, FieldValues } from 'react-hook-form'
import { Icon } from '../Icon/Icon'

export type InputProps = {
  children?: React.ReactNode
  classNameInputELement?: string
  classNameInputContainer?: string
  classNameInputWrapper?: string
  classNameInputPlaceholder?: string
  classNameInputError?: string
  name: string
  type?: 'text' | 'email' | 'tel' | 'password' | 'number' | 'radio'
  maxLength?: number
  required?: boolean
  autoComplete?: string
  placeholder?: string
  disabled?: boolean
  defaultValue?: string | null
  hasInitialValue?: boolean
  id?: string
}

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  register: UseFormRegister<TFormValues>
  rules: RegisterOptions
  errors: FieldErrors<TFormValues>
} & Omit<InputProps, 'name'>

export const Input = <TFormValues extends FieldValues>({
  children,
  required,
  name,
  errors,
  maxLength,
  autoComplete,
  disabled,
  placeholder,
  classNameInputELement = '',
  classNameInputContainer = '',
  classNameInputWrapper = '',
  classNameInputPlaceholder = '',
  classNameInputError = '',
  register,
  rules,
  defaultValue = '',
  hasInitialValue,
  id,
  ...rest
}: FormInputProps<TFormValues>) => {
  const hasError = !!errors?.[name]
  const { t } = useTranslation()

  //To ensure autofilled value is detected, animation on autofill-pseudo element is set up
  const [isAutofillOnMountActive, setIsAutofillOnMountActive] = React.useState(false)
  const [value, setValue] = React.useState(defaultValue)

  return (
    <div className={clsx(classNameInputWrapper, 'relative')}>
      <div
        className={clsx(
          classNameInputContainer && classNameInputContainer,
          'input-wrapper relative mb-6',
        )}
      >
        <input
          onAnimationStart={(e) => {
            if ((e.target as HTMLInputElement).value) {
              setIsAutofillOnMountActive(true)
              setValue((e.target as HTMLInputElement).value)
            }
          }}
          disabled={disabled}
          maxLength={maxLength}
          required={required}
          autoComplete={autoComplete}
          value={value ?? undefined}
          className={clsx(
            styles.input,
            '!focus:outline-none z-10 w-full appearance-none rounded-[5px] border border-solid bg-transparent px-[19px] py-[12px] text-12-20-sg placeholder-grey-dark caret-grey-dark !outline-none outline-0 transition-all focus:ring-0',
            hasError && !disabled
              ? 'border-sg-red text-sg-red focus:border-sg-red'
              : disabled
              ? 'border-grey-border'
              : 'border-grey-border focus:border-primary-black',
            disabled && 'pointer-events-none',
            'peer',
            classNameInputELement && classNameInputELement,
          )}
          onFocus={(e) => {
            setValue(e.target.value)
            setIsAutofillOnMountActive(false)
          }}
          {...rest}
          {...(register &&
            register(name, {
              onChange: (e) => {
                setValue(e.target.value)
                setIsAutofillOnMountActive(false)
              },

              ...rules,
            }))}
          id={id}
          placeholder={`${placeholder}${!rules.required ? ` (${t('Optional')})` : '*'}`}
        ></input>
        {children}

        {/* <label
          htmlFor={id}
          placeholder='placeholder'
          className={clsx(
            styles.inputLabel,
            'height-5 absolute left-0 z-0 text-grey-dark transition-all',
            // isAutofillOnMountActive || value || hasInitialValue
            //   ? 'top-0 block text-p-small'
            //   : 'top-7',
            disabled && 'text-grey-medium',
            // 'peer-focus:top-0 peer-focus:block peer-focus:text-p-small',
            classNameInputPlaceholder && classNameInputPlaceholder,
          )}
        />
          {placeholder}
          {!rules.required ? ` (${t('Optional')})` : '*'}
        </label> */}
        {/* <div className={clsx(!hasError && styles.inputBorder)}></div> */}
      </div>
      {hasError && !disabled && (
        <p className="absolute right-4 top-1/2 -translate-y-1/2">
          <Icon name="error" className="h-4.5 w-4.5" />
        </p>
      )}
      {!disabled && (
        <ErrorMessage
          errors={errors}
          name={name as any}
          render={({ message }) => (
            <p
              className={clsx(
                'error-message absolute top-full ml-5 pt-0.5 text-p-small font-extralight text-primary-red',
                classNameInputError && classNameInputError,
              )}
            >
              {t(message)}
            </p>
          )}
        />
      )}
    </div>
  )
}
